import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueParallaxJs from "vue-parallax-js";
import VueYoutube from "vue-youtube";
import scrollAnalytics from "@/components/scroll-analytics.vue";

Vue.component('scrollAnalytics', scrollAnalytics)

// import style
import "swiper/css/swiper.css";

Vue.use(VueParallaxJs);
Vue.use(VueYoutube);

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.config.productionTip = false;
Vue.use(i18n);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
