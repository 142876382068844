<template>
  <div class="slide"></div>
</template>

<script>
export default {
  name: "scroll-analytics",

  data() {
    return {
      trigger25: true,
      trigger50: true,
      trigger75: true,
      trigger90: true,
    }
  },

  mounted() {
    document.addEventListener('scroll', () => {

      var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && percent < 50 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll', {'event_category': 'moreThan25%', 'event_label': this.$route.path})
      } else if (percent >= 50 && percent < 75 && this.trigger50) {
        this.trigger50 = false
        gtag('event', 'scroll', {'event_category': 'moreThan50%', 'event_label': this.$route.path})
      } else if (percent >= 75 && percent < 90 && this.trigger75) {
        this.trigger75 = false
        gtag('event', 'scroll', {'event_category': 'moreThan75%', 'event_label': this.$route.path})
      } else if (percent >= 90 && this.trigger90) {
        this.trigger90 = false
        gtag('event', 'scroll', {'event_category': 'moreThan90%', 'event_label': this.$route.path})
      }

      return false
    });
  },

}
</script>

<style scoped lang="sass">

</style>
