import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
  },
  {
    path: "/cases/periods",
    name: "Periods",
    component: () => import("../views/cases/Periods.vue"),
  },
  {
    path: "/cases/listerine",
    name: "Listerine",
    component: () => import("../views/cases/Listerine.vue"),
  },
  {
    path: "/cases/butt",
    name: "Butt",
    component: () => import("../views/cases/Butt.vue"),
  },
  {
    path: "/cases/allegra",
    name: "Allegra",
    component: () => import("../views/cases/Allegra.vue"),
  },
  {
    path: "/cases/hepato",
    name: "Hepato",
    component: () => import("../views/cases/Hepato.vue"),
  },
  {
    path: "/cases/vitrolla",
    name: "Vitrolla",
    component: () => import("../views/cases/Vitrolla.vue"),
  },
  {
    path: "/cases/shift-work",
    name: "ShiftWork",
    component: () => import("../views/cases/ShiftWork.vue"),
  },
  {
    path: "/cases/vacciland",
    name: "Vacciland",
    component: () => import("../views/cases/Vacciland.vue"),
  },
  {
    path: "/cases/happy-liver",
    name: "HappyLiver",
    component: () => import("../views/cases/HappyLiver.vue"),
  },
  {
    path: "/cases",
    name: "Cases",
    component: () => import("../views/Cases.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/privacy-policy.vue"),
  },
  {
    path: "/code-of-conduct",
    name: "conduct",
    component: () => import("../views/conduct.vue"),
  },
  {
    path: "/data-processing-agreement",
    name: "data-processing-agreement",
    component: () => import("../views/data-processing-agreement.vue"),
  },
  {
    path: "/term-of-service",
    name: "privacy-policy",
    component: () => import("../views/term-of-service.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/clm",
    name: "CLM",
    component: () => import("../views/services/CLM.vue"),
  },
  {
    path: "/services/medical-web",
    name: "MedicalWeb",
    component: () => import("../views/services/MedicalWeb.vue"),
  },
  {
    path: "/services/medical-vr",
    name: "MedicalVR",
    component: () => import("../views/services/MedicalVR.vue"),
  },
  {
    path: "/services/medical-explainer",
    name: "Explainer",
    component: () => import("../views/services/Explainer.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },

  {
    path: "/404",
    name: "not-found",
    component: () => import("../views/not-found.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: 'history',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash};
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
});

export default router;
