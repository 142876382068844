import anime from 'animejs';

const animTitle = (name, num1, num2) => {
  const structure = document.querySelector(`.${name}`);

  const textWrapperAll = structure.querySelectorAll('.text-wrapper .letters');
  textWrapperAll.forEach((e, index) => {
    const textWrapper = e;
    if (!Number.isInteger(index / 2)) {
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");
    } else {
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    }
  });
  anime({
    targets: '.text-wrapper .letter',
    translateY: ['1.2em', 0],
    translateZ: 0,
    duration: 750,
    count: 1,
    // eslint-disable-next-line consistent-return
    delay: (el, i) => {
      const ind = i - num1;
      return ind * num2;
    },
  });
};

export default animTitle;
